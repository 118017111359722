import React from 'react';

import styled, { css } from 'styled-components';
import { Icon } from 'antd';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import _ from 'lodash';
import Router, { useRouter } from 'next/router';

import useForm from 'react-hook-form';
import Cookies from 'js-cookie';
import * as Queries from './queries';
import * as AuthQueries from '../auth/queries';
import getUserLayerInfo from '../../lib/utils';

const LoginForm = () => {
  const client = useApolloClient();
  const router = useRouter();
  const redirectTo = _.get(router, 'query.redirectTo');
  const { register, handleSubmit, errors } = useForm();
  const [tokenAuth, { loading, error: loginError }] = useMutation(Queries.TOKEN_AUTH);
  const [updateAuth] = useMutation(AuthQueries.UPDATE_AUTH);
  const onSubmit = async formData => {
    const tokenRes = await tokenAuth({ variables: { ...formData } });
    await updateAuth({ variables: { isLoggedIn: true } });
    Cookies.set('token', _.get(tokenRes, 'data.tokenAuth.token'));
    Cookies.set('refreshToken', _.get(tokenRes, 'data.tokenAuth.refreshToken'));
    const userLayerInfo = await getUserLayerInfo(client);
    window.dataLayer.push(userLayerInfo);
    await Router.push({
      pathname: redirectTo || '/',
      query: { isLogged: router.asPath }
    });
    window.scrollTo(0, 0);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title>로그인</Title>
      <Item>
        <Label>휴대폰 번호</Label>
        <Input
          name="username"
          type="number"
          placeholder="- 없이 숫자만 입력"
          ref={register({ pattern: /^[0-9]{10,11}$/ })}
        />
        {errors.username && <Error>휴대폰 번호 10-11자리를 입력하세요</Error>}
      </Item>

      <Item>
        <Label>비밀번호</Label>
        <Input
          name="password"
          type="password"
          placeholder="영문, 숫자 조합 8자리 이상"
          ref={register({ required: true })}
        />

        {errors.password && <Error>비밀번호를 입력해주세요.</Error>}
      </Item>
      {loading ? (
        <LoadingSubmit>
          <LoadingIcon type="loading" style={{ fontSize: 24 }} spin />
        </LoadingSubmit>
      ) : (
        <Submit type="submit" value="로그인" />
      )}

      {loginError && <Error>휴대폰 번호가 존재하지 않거나, 비밀번호가 유효하지 않습니다.</Error>}
    </Form>
  );
};

const Form = styled.form`
  text-align: left;
  padding: 0 2rem;
  margin-bottom: 3rem;
`;

const Title = styled.h1`
  font-weight: 700;
  margin-top: 5rem;
  margin-bottom: 4rem;
  text-align: center;
  ${props => props.theme.device.md} {
    margin-top: 7.2rem;
    font-size: ${props => props.theme.fontSize.desktop.headlineTwo};
  }
`;

const Item = styled.div`
  margin-bottom: 1.6rem;
`;

const Label = styled.label`
  display: block;
  font-size: ${props => props.theme.fontSize.mobile.bodyOne};
  font-weight: 700;
  color: ${props => (props.focus ? '#2F6ECD' : '#323232')};
  ${props => props.theme.device.md} {
    font-size: ${props => props.theme.fontSize.desktop.caption};
  }
`;

const Input = styled.input`
  width: 100%;
  height: 4rem;
  border: 0px;
  background-color: #f5f4f5;
  border-bottom: 1px solid #808080;
  font-size: ${props => props.theme.fontSize.mobile.bodyOne};
  font-weight: 700;
  color: #323232;
  ${props => props.theme.device.md} {
    font-size: ${props => props.theme.fontSize.desktop.bodyThree};
    height: 4.8rem;
  }

  &&:focus {
    border-bottom: 2px solid #ff7e1f;
  }

  &&::placeholder {
    color: #bdbdbd;
  }
`;

const Error = styled.div`
  font-size: ${props => props.theme.fontSize.mobile.bodyTwo};
  font-weight: 700;
  color: #eb5757;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`;

const submitStyles = css`
  width: 100%;
  height: 4.8rem;
  background-color: #ff7e1f;
  border-radius: 4px;
  font-size: ${props => props.theme.fontSize.mobile.button};
  font-weight: 700;
  color: #fff;
  padding: unset;
  border: unset;
  cursor: pointer;

  ${props => props.theme.device.md} {
    font-size: ${props => props.theme.fontSize.desktop.buttonOne};
    height: 5.4rem;
  }
`;

const Submit = styled.input`
  ${submitStyles}
`;

const LoadingIcon = styled(Icon)`
  margin: 14px 0;
`;
const LoadingSubmit = styled.div`
  ${submitStyles}
  text-align: center;
  background-color: #bdbdbd;
`;

export default LoginForm;
